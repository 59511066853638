.about-main {
    padding: 60px 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4; /* Light background color */
    color: var(--main-red); /* Primary text color */
    margin-top: 40px;
}

.main-content {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 80%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
}

/* Portrait and bio button */
.main-left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.portrait-square {
    margin-top: 50px;
    height: 50vh;
    border-radius: 2%; 
    object-fit: cover;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 50px;
}

/* Text and calculator button */
.main-right {
    max-width: 600px;
    margin-top: -20px;
}

@media (max-width: 850px) {
    .about-main {
        padding-top: 20px;
    }
    .main {
        height:auto;
        
    }
    .portrait-square {
        margin-top: 0px;
    }
    .main-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

}
/*
@media (prefers-color-scheme: dark) {
    .about-main {
        background-color: rgb(25, 25, 25);
        color: #f4f4f4;
    }

    .portrait-square {
        box-shadow: 0 4px 10px rgba(99, 99, 99, 0.3);
    }
 
   }
    */