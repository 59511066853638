body {
  margin: 0;
  font-family: 'Georgia', 'Montesserat';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
    text-decoration: none;
}

/* 
@media (prefers-color-scheme: dark) {
   body {
    background-color: rgb(25, 25, 25);
   }

}
   */

:root {
    --main-blue:#0c3571;
    --main-red:#A80532;
    --main-red-hover:#8e0028;
}
