.blogs {
    padding: 60px 35vw;
    display: flex;
    flex-direction: column;
    /* align-items: center; Center the blog items horizontally */
    background-color: #f4f4f4; /* Light background color */
    color: var(--main-red); /* Primary text color */
    margin-top: 0px;
    height: auto;
}

.blog-item {
    max-width: 700px; /* Limit the width of each blog item */
    text-align: left; /* Left-align the content inside each blog item */
    margin-bottom: 20px; /* Add some spacing between blog items */
}

.blog-item h2 {
    text-align: left; /* Ensure the title is left-aligned */
    margin: 0; /* Reset default margins */
    padding: 0; /* Reset padding */
}

.blog-date {
    text-align: left; /* Ensure the date is also left-aligned */
    font-size: 12px; /* Adjust font size for dates */
    color: #333; /* Optional: adjust the color for better readability */
}

.blog-item-a {
    text-decoration: none; /* Ensure links are also aligned */
    color: var(--main-red); /* Optional: standard link color */
}
.container-news {
    margin-top: 40px;
}

@media (max-width: 1000px) {
    .blogs {
        padding: 60px 10vw;
    }
    

}
