/* Container and form layout */
.form-container {
    width: 100%;
    max-width: 600px;
    margin: 0px auto;
    padding: 40px;
    background-color: #ffffff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
}
.std-body {
    background-color: #f9f9f9;
    height: auto;
    min-height: 80vh;
    padding-top: 40px;
    padding-bottom: 40px;
    margin-top: 40px;
}

.form-container h1 {
    text-align: center;
    color: var(--main-red);
    margin-bottom: 20px;
    /* font-family: 'Helvetica', sans-serif; */
}

.contact-form {
    display: flex;
    flex-direction: column;
}

/* Form group layout */
.form-group {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
}

.form-group label {
    margin-bottom: 8px;
    font-weight: 600;
    color: #444;
    /* font-family: 'Arial', sans-serif; */
}

.form-group input,
.form-group textarea {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease;
    background-color: #fff;
}

/* Hover and focus effects */
.form-group input:focus,
.form-group textarea:focus {
    border-color: #4CAF50;
    outline: none;
}

.form-group textarea {
    resize: vertical;
    min-height: 100px;
}

/* Submit button styles */
.submit-btn {
    padding: 12px 20px;
    font-size: 18px;
    background-color: var(--main-red);
    color: white;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submit-btn:hover {
    background-color: var(--main-red-hover);
}

/* Added checkbox styling */
.checkbox-group {
    display: flex;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 20px;
    
}

.checkbox-group input {
    margin-left: 100px;
    transform: scale(1.2);
    
}

.checkbox-group label {
    font-size: 14px;
    color: #444;
}

.checkbox-wrapper-7 a {
    color: var(--main-red);
    text-decoration: none;
    font-weight: 600;
}

.checkbox-group a:hover {
    text-decoration: underline;
}


/* Responsive form */
@media (max-width: 600px) {
    .form-container {
        padding: 10px;
    }

    .form-group input,
    .form-group textarea {
        font-size: 14px;
    }

    .submit-btn {
        font-size: 16px;
    }
}


.checkbox-wrapper-7 .tgl {
    display: none;
}
.checkbox-wrapper-7 .tgl,
.checkbox-wrapper-7 .tgl:after,
.checkbox-wrapper-7 .tgl:before,
.checkbox-wrapper-7 .tgl *,
.checkbox-wrapper-7 .tgl *:after,
.checkbox-wrapper-7 .tgl *:before,
.checkbox-wrapper-7 .tgl + .tgl-btn {
    box-sizing: border-box;
}
.checkbox-wrapper-7 .tgl::-moz-selection,
.checkbox-wrapper-7 .tgl:after::-moz-selection,
.checkbox-wrapper-7 .tgl:before::-moz-selection,
.checkbox-wrapper-7 .tgl *::-moz-selection,
.checkbox-wrapper-7 .tgl *:after::-moz-selection,
.checkbox-wrapper-7 .tgl *:before::-moz-selection,
.checkbox-wrapper-7 .tgl + .tgl-btn::-moz-selection,
.checkbox-wrapper-7 .tgl::selection,
.checkbox-wrapper-7 .tgl:after::selection,
.checkbox-wrapper-7 .tgl:before::selection,
.checkbox-wrapper-7 .tgl *::selection,
.checkbox-wrapper-7 .tgl *:after::selection,
.checkbox-wrapper-7 .tgl *:before::selection,
.checkbox-wrapper-7 .tgl + .tgl-btn::selection {
    background: none;
}
.checkbox-wrapper-7 .tgl + .tgl-btn {
    outline: 0;
    display: block;
    width: 4em;
    height: 2em;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:after,
.checkbox-wrapper-7 .tgl + .tgl-btn:before {
    position: relative;
    display: block;
    content: "";
    width: 50%;
    height: 100%;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:after {
    left: 0;
}
.checkbox-wrapper-7 .tgl + .tgl-btn:before {
    display: none;
}
.checkbox-wrapper-7 .tgl:checked + .tgl-btn:after {
    left: 50%;
}

.checkbox-wrapper-7 .tgl-ios + .tgl-btn {
    background: #fbfbfb;
    border-radius: 2em;
    padding: 2px;
    transition: all 0.4s ease;
    border: 1px solid #e8eae9;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:after {
    border-radius: 2em;
    background: #fbfbfb;
    transition: left 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), padding 0.3s ease, margin 0.3s ease;
box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1), 0 4px 0 rgba(0, 0, 0, 0.08);
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:hover:after {
    will-change: padding;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:active {
    box-shadow: inset 0 0 0 2em #e8eae9;
}
.checkbox-wrapper-7 .tgl-ios + .tgl-btn:active:after {
    padding-right: 0.8em;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn {
    background: #86d993;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn:active {
    box-shadow: none;
}
.checkbox-wrapper-7 .tgl-ios:checked + .tgl-btn:active:after {
    margin-left: -0.8em;
}

  

@media (max-width: 850px) {
    .form-container {
        width: 80%;
    }

}
