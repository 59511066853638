/* General container styling */
.mc_embed_signup {
    display: flex;
    justify-content: center;
    align-items: center;
    /* height: 100vh; */
    background-color: #f4f4f4;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
    text-align: center;
  }


  .button {
    background-color: #fff; 
    color: var(--main-red); 
    padding: 10px 30px; 
    border: none;
    border-radius: 30px; 
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
    outline: none;
    display: inline-block;
}

.button:hover {
    background-color: var(--main-red-hover); 
    color: white;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4); 
    transform: translateY(-2px); /* Slight lift effect on hover */
}

.button:active {
    transform: translateY(1px); /* Button sinks slightly when clicked */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3); /* Reduced shadow on click */
}

.close-button {
    background-color: transparent;
    font-size: 2rem;
    box-shadow: none;
    text-align: right;
    justify-content: right;
    align-items: right;
    display: flex;
    padding: 5px 15px;
    position: relative;
    left: 270px; 
    margin-bottom: none;
    padding-bottom: 0;

  
}

.close-button:hover {
    background-color: white; 
    color: var(--main-red-hover);
    transform: none; /* Slight lift effect on hover */
    /* border-radius: 0; */
    box-shadow: none;


}

.mailchimp-h2 {
    margin-top: 0;
    padding-top: 0;
}

@media (max-width: 650px) {
    .close-button {
        left: 175px; 
    }
    
}