.footer {
    background-color: #f8f8f8; /* Light gray background */
    padding: 20px;
    border-top: 1px solid #ddd;
    /* position: fixed;  */
    bottom: 0;
    /* width: 100%; */

}

.footer-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    flex-wrap: wrap;
}

/* Logo and Text Section */
.footer-logo-text {
    display: flex;
    align-items: center; /* Aligns logo and text vertically */
    gap: 10px; /* Space between logo and HomeSmart text */
}

/* Logo Section */
.footer-logo img {
    width: 150px; /* Adjust as needed */
}

/* Text Section */
.footer-text {
    color: #ffffff; /* Text color for HomeSmart */
}

/* Contact & Office Info */
.contact-info, .office-info {
    display: flex;
    flex-direction: column;
    color: #333;
}

/* Links */
.footer a {
    text-decoration: none;
    color: var(--main-red); /* Primary color */
}

.social a{
    margin-right: 10px;
    color: var(--main-red);
}
.social img:hover {
    -webkit-filter: invert(50%); /* safari 6.0 - 9.0 */
    filter: invert(50%);      
}
hr.solid {
    opacity: 0;
}

/* Responsive Layout */
@media (max-width: 768px) {
    .footer-content {
        flex-direction: column;
        align-items: flex-start;
        text-align: left;
    }

    .footer-text {
        margin-bottom: 20px; /* Adds space below HomeSmart text */
    }
    .footer-logo {
        /* opacity: 0;  */
        /* visibility: none; */
        transform: translateX(calc(55vw));
        position: absolute;

    }
    hr.solid {
        border-top: 3px solid #bbb;
        width: 100%;
        opacity: 1;

      }

    .social {
        margin-bottom: 0; /* Remove any margin below social icons */
    }

    .social img {
        width: 40px;
        display: flex;
        margin: 0 5px; /* Adjust spacing between icons */
        z-index: 1;
        position: relative;
        left: 77.5vw;
        bottom: 45px;
    }
    .call {
        margin-top: 10px; /* Ensure some space between icons and the contact number */
        transform: none; /* Reset any previous transform */
        padding-bottom: auto; /* Ensure the number aligns properly */
        position: relative;
        bottom: 100px; /* Adjust to bring it closer to social icons */
        margin-bottom: -30px;
    }
    .name {
        padding-top: 25px;
    }
    .footer {
        padding-bottom: 0;
        padding-top: 50px;
    }
      
    
}


/* 
@media (prefers-color-scheme: dark) {
    .footer {
        background-color: rgb(25, 25, 25);
    }
    .contact-info, .office-info, .footer a {
        color: white
    };

} 
*/