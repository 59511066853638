

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
    margin: auto;
    padding: 0px 10px;
    /* background-color: #c50000; */
    color: black;
    /* box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25); */
    width: 95%;
    height: 80px;
    border-radius: 13px;
    /* position: fixed; */
    position: relative;
    top: 20px;
    /* left: 50%;
    transform: translateX(-50%); */
}
.nav-title {
    color: #000;
    justify-self: start;
    cursor: pointer;
    white-space: nowrap;
    padding-top: 5px;

}

.nav-title a {
    text-decoration: none;
    color: #000;
}
.nav-title i {
    color: var(--main-red);
}


.fa-code {
    margin-left: 0.2rem;
    font-size: 1.6rem;
}

.nav__menu {
    display: grid;
    grid-template-columns: repeat(4, auto);
    grid-gap: 10px;
    list-style: none;
    align-items: center;
    text-align: center;
    justify-content: end;
}

.nav-links {
    text-decoration: none;
    color: #000;
    padding: 0.7rem 1rem;
    white-space: nowrap;
    border-radius: 15px;
}

.nav-links i {
    padding-right: 10px;
    color: #A80532;

}
.nav-links:hover i {
    color: #fff; 
}

.nav-links:hover {
    background-color: #A80532;
    color: #fff;
    border-radius: 25px;
    transition: all 0.2s ease-in-out;
}



.fa-bars, 
.fa-times {
    color: var(--main-red);

}

.menu-icon {
    display: none;
}

.tooltip {
    /* visibility: none; Hide tooltip initially */
    background-color: var(--main-red); /* Dark background */
    color: #fff; /* White text */
    text-align: center;
    border-radius: 25px;
    padding: 5px 10px;
    position: absolute;
    bottom: -15px; /* Adjust to position below the icon */
    transform: translateX(-50%);
    white-space: nowrap; /* Prevent text wrapping */
    font-size: 14px; /* Adjust font size */
    z-index: 1;
    opacity: 0;
    transition: all 0.1s ease-in-out;
    left: calc(1.2rem + 13px); /* DESKTOP SPECIFIC */
}

.nav-title:hover .tooltip {
    /* visibility: visible; Show tooltip on hover */
    opacity: 1;
}


@media screen and (max-width: 850px) {
    .navbar {
        z-index: 99;
        /* right: 30px; */
        width: 95%; /* Adjusts to prevent overflow */
        margin: 0; /* Remove any margin */
        border-radius: 0; /* Remove border-radius if you want full-width */
    }

    .nav__menu {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        background: rgb(255, 255, 255);
        /* box-shadow: 0 8px 23px 0 rgba(255, 255, 255, 0.37); */
        backdrop-filter: blur(100px);
        border-radius: 0px;
        width: 102.5%;
        height: auto;
        position: absolute;
        top: -100%;
        left: -10px; /* originally 0 */
        align-items: stretch;
        padding: 80px 0 30px 0;
        margin: 0;
        z-index: -1;
        /* transition: transform 0.2s cubic-bezier(0.2, 0.5, 0.44, 1) 0s; */
        transform: translateY(-100%);
    }


    .nav__menu.active {
        transform: translateY(0%);
        top: 0;
    }

    .nav__menu.active ~ .navbar {
        background-color: transparent;
        box-shadow: none;
    }

    .nav-links {
        display: block;
        widows: 100%;
        padding: 2rem 0;
        color: rgba(0, 0, 0, 0.75);
    }

    .nav-links:hover {
        background-color: var(--main-red);
        /* backdrop-filter: blur(20px); */
        border-radius: 13px;
        width: 50vw;
        left: 25%;
        position: sticky;

    }

    .nav-title {
        font-size: xx-large;
    
    }
    

    .menu-icon {
        display: block;
        margin-right: 30px;
    }

    .tooltip {
        left: 50%; /* MOBILE SPECIFIC */
        transition: none;
        
    }
    .nav-title:hover .tooltip {
        /* visibility: visible; Show tooltip on hover */
        opacity: 0;
    }
}

/* 
@media (prefers-color-scheme: dark) {
    .nav-title {
        color: #fff;
    }
    .nav-title a{
        color: #fff;
    }
  
    .nav-links {
        color: #fff;
    }  

    .fa-bars, 
    .fa-times {
        color: #fff;

    }

  }
    */