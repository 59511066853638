.mortgage-calculator-container {
    padding: 60px 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    color: var(--main-red);
    min-height: 67vh;
    margin-top: 40px;
}

h1 {
    font-size: 2.5rem;
    margin-bottom: 40px;
}

.mortgage-form {
    background: white;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
}

.form-group {
    margin-bottom: 20px;
}

.form-group label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
    font-size: 1rem;
}

.form-group input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 1rem;
}

.calculate-btn {
    padding: 15px 20px;
    background-color: var(--main-red);
    color: white;
    font-size: 1.2rem;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    width: 100%;
}

.calculate-btn:hover {
    background-color: var(--main-red-hover);
}

.result {
    margin-top: 30px;
    max-width: 800px
}

.result h2 {
    font-size: 1.5rem;
    color: #333;
}

@media (max-width: 850px) {
    .mortgage-calculator-container {
        padding-top: 20px;
    }

    .mortgage-form {
        width: auto;
    }
    .form-group input {
        width: 90%;
    }
    

}


/* 
@media (prefers-color-scheme: dark) {
    .mortgage-calculator-container {
        background-color: rgb(25, 25, 25);
        color: #f4f4f4;
    }
    .mortgage-form {
        background-color: #f4f4f4;
    }
    .portrait-square {
        box-shadow: 0 4px 10px rgba(99, 99, 99, 0.3);
    }
    .result h2 {
        color: #f4f4f4;
    }
    .form-group label {
        color: #0c3571;
    }
   }
    */