button {
    background-color: #fff; 
    color: var(--main-red); 
    padding: 10px 30px; 
    border: none;
    border-radius: 30px; 
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3); 
    outline: none;
    display: inline-block;
}

button:hover {
    background-color: var(--main-red-hover); 
    color: white;
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.4); 
    transform: translateY(-2px); /* Slight lift effect on hover */
}

button:active {
    transform: translateY(1px); /* Button sinks slightly when clicked */
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3); /* Reduced shadow on click */
}

.main {
    display: flex-row;
    text-align: center;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.sec1 {
    background-image: url('../../Images/outside.jpg'); 
    background-size: cover; 
    background-position: center;
    background-repeat: no-repeat; 
    height: calc(100vh - 150px); 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #fff;
    padding: 20px;
    position: relative;
    /* box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.5); */  
}

.sec1::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* image overlay */
    z-index: 1;
    border-radius: inherit;
}

.sec1 h1, .sec1 h2, .sec1 h3, .sec1 button {
    position: relative;
    z-index: 2;
}


.sec1 h1 {
    font-size: xxx-large;
}

.sec1 h2 {
    font-size: xx-large;
}

.sec1 h3 {
    font-size: x-large;
    margin-top: 0px;
}

.sec2 h1 {
    font-size: xx-large;
    align-items: center;
    justify-content: center;

}

.sec2 {
    padding: 60px 20px;
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4; /* Light background color */
    color: var(--main-red); /* Primary text color */
    height: auto; 
    padding-bottom: 60px; /* Add padding to avoid collision */
}

.sec2-content {
    display: flex;
    flex-direction: row;
    gap: 40px;
    width: 80%;
    max-width: 1200px;
    justify-content: space-between;
    align-items: center;
}

/* Portrait and bio button */
.sec2-left {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.portrait-container {
    width: 200px;
    height: 200px;
    overflow: hidden; /* Ensures the image is contained within the circle */
    border-radius: 50%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    margin-bottom: 20px;
}

.portrait {
    width: 130%;
    height: auto;
    transform: translate(-12px, 0); /* Move the image left */
    object-fit: cover;
}
/* Text and calculator button */
.sec2-right {
    max-width: 600px;
    margin-top: -20px;
}
.lessmargin {
    margin-top: 0px;
    margin-bottom: 25px;
}

.lessmarginh2 {
    margin-top: 0px;
}

@media (max-width: 850px) {
    .sec2 {
        height:auto;
    }

    .sec2-content {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }
    .lessmargin {
        text-align: center;
    }


}

/*
@media (prefers-color-scheme: dark) {
    .sec2 {
     background-color: rgb(25, 25, 25);
     color: #f4f4f4;
    }

    .portrait {
        width: 200px;
        height: 200px;
        border-radius: 50%; 
        object-fit: cover;
        box-shadow: 0 4px 10px rgba(255, 255, 255, 0.3);
        margin-bottom: 20px;
    }
 
   }

   */